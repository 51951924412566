import React from 'react'
import { graphql } from 'gatsby'
import { Container, Grid, useMediaQuery, useTheme } from '@mui/material'

import Section from '_core/components/atoms/layout/Section'

import Layout from 'public/components/organisms/global/Layout'
import PageTitleHero from 'public/components/organisms/hero/PageTitleHero'

import TextSection from 'public/components/organisms/sections/TextSection'
import SectionHeading from 'public/components/molecules/sections/SectionHeading'
import TeamMemberCard from 'public/components/molecules/cards/teamMember/TeamMemberCard'

interface Props {
  data: Queries.TeamLandingQuery
}

const TeamLanding = ({ data }: Props) => {
  const page = data.datoCmsLandingPage
  const team = data.allDatoCmsTeamMember.edges
  const theme = useTheme()
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <Layout
      metadata={{
        seoTags: page.seoMetaTags,
      }}
    >
      <PageTitleHero
        mainHeading={page.heroMainHeading}
        smallHeading={page.heroSmallHeading}
        imageLandscape={page.imageLandscape.gatsbyImageData}
        imagePortrait={page.imageLandscape.gatsbyImageData}
      />
      <TextSection
        leadText={page.textSectionHeroText}
        smallHeading={page.textSectionSmallHeading}
        text={page.textSectionBodyNode.childMarkdownRemark.html}
      />
      <Section bgcolor="lightGrey" overflowXHidden overflowYHidden vSpace>
        <SectionHeading sx={{ typography: 'h3' }} heading="Our Team" />

        <Container maxWidth={xlUp ? 'xl' : 'lg'}>
          <Grid container spacing={xlUp ? 4 : 2}>
            {team.map((item: any) => {
              const member: Queries.DatoCmsTeamMember = item.node
              return <TeamMemberCard key={member.originalId} member={member} />
            })}
          </Grid>
        </Container>
      </Section>
    </Layout>
  )
}

export default TeamLanding

export const pageQuery = graphql`
  query TeamLanding {
    datoCmsLandingPage(originalId: { eq: "1448135" }) {
      ...LandingPageTitleHero
      textSectionBodyNode {
        childMarkdownRemark {
          html
        }
      }
      textSectionHeroText
      textSectionSmallHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsTeamMember(sort: { order: ASC, fields: position }) {
      edges {
        node {
          model {
            apiKey
          }
          slug
          jobTitle
          linkedinProfileUrl
          emailAddress
          bio
          name
          originalId
          imageLandscape: image {
            gatsbyImageData(
              width: 600
              layout: CONSTRAINED
              imgixParams: {
                ar: "420:378"
                fit: "crop"
                crop: "focalpoint"
                sat: -100
              }
            )
          }
          imagePortrait: image {
            gatsbyImageData(
              width: 400
              layout: CONSTRAINED
              imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
            )
          }
          bioNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
