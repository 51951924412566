import React, { memo } from 'react'
import {
  GatsbyImage,
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image'
import {
  Box,
  Container,
  ContainerProps,
  Typography,
  TypographyProps,
  styled,
  useTheme,
} from '@mui/material'

import Flex from '_core/components/atoms/layout/Flex'
import BackgroundPlx from 'public/components/atoms/plx/BackgroundPlx'

export interface PageTitleHeroProps {
  smallHeading: string
  mainHeading: string
  mainHeadingProps?: TypographyProps
  imageLandscape: IGatsbyImageData
  imagePortrait: IGatsbyImageData
  children?: React.ReactNode
  twoColContentLayout?: boolean
}

const OuterWrapper = styled(Flex)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  color: 'white',
  textAlign: 'center',
  ['&, .fixed-height']: {
    minHeight: '400px',
  },
  [theme.breakpoints.up('md')]: {
    ['&, .fixed-height']: {
      minHeight: '500px',
    },
  },
  [theme.breakpoints.up('md')]: {
    ['&, .fixed-height']: {
      minHeight: '600px',
    },
  },
  ['&.extra-height']: {
    [theme.breakpoints.up('xl')]: {
      paddingBottom: theme.twoColLayoutExtraHeight,
    },
  },
}))

const BgImage = styled(GatsbyImage)(({ theme }) => ({
  opacity: 0.15,
  height: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'block',
    height: '0',
    paddingTop: '166.6666666666667%',
  },
}))

const PageTitleHero = ({
  mainHeading,
  mainHeadingProps,
  smallHeading,
  imagePortrait,
  imageLandscape,
  twoColContentLayout,
  children,
}: PageTitleHeroProps) => {
  const theme = useTheme()

  let outerClass
  if (twoColContentLayout) {
    outerClass = 'extra-height'
  }

  const images = withArtDirection(
    // @ts-ignore
    getImage(imageLandscape),
    [
      {
        media: `(max-width: ${theme.breakpoints.values.md}px)`,
        image: getImage(imagePortrait),
      },
    ]
  )

  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: 0,
          endValue: 50,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  return (
    <OuterWrapper center className={outerClass}>
      <Box bgcolor="textDark" height="100%" width="100%">
        <BackgroundPlx parallaxData={parallaxData}>
          <BgImage image={images} alt="" loading="eager" />
        </BackgroundPlx>

        <Flex center full className="fixed-height">
          <Container maxWidth="xl" sx={{ textAlign: 'center', py: 10 }}>
            {smallHeading && (
              <Box typography="smallText" className="border" mb={1}>
                {smallHeading}
              </Box>
            )}
            <Typography
              variant="h1"
              sx={{ display: 'block' }}
              {...mainHeadingProps}
            >
              {mainHeading}
            </Typography>
            {children}
          </Container>
        </Flex>
      </Box>
    </OuterWrapper>
  )
}

export default memo(PageTitleHero)
