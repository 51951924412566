import React, { memo } from 'react'
import { Divider as DividerBase, DividerProps, styled } from '@mui/material'

const Divider = styled(DividerBase)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '6.6rem',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const HeadingDivider = ({ ...props }: DividerProps) => <Divider {...props} />

export default memo(HeadingDivider)
