import React, { memo } from 'react'
import {
  Card as CardBase,
  CardProps as CardBaseProps,
  styled,
} from '@mui/material'

interface Props {
  whiteBg?: boolean
  disableHover?: boolean
  overflow?: boolean
  fullHeight?: boolean
}
interface CardProps extends Props, CardBaseProps {}

const StyledCard = styled(CardBase, {
  shouldForwardProp: (prop) =>
    prop !== 'whiteBg' &&
    prop !== 'disableHover' &&
    prop !== 'overflow' &&
    prop !== 'fullHeight',
})<Props>(({ whiteBg, disableHover, overflow, fullHeight, theme }) => ({
  position: 'relative',
  background: theme.palette.common.white,
  borderRadius: theme.shape?.borderRadiusLarge,
  overflow: 'hidden',
  ['.heading-link']: {
    color: 'inherit',
    ['> *']: {
      transition: theme.transitions.create(['color']),
      ['&:hover']: {
        color: theme.palette.primary.main,
      },
    },
  },
  // card effect
  boxShadow: theme.palette.shadows.light,
  top: 0,
  transition: theme.transitions.create(['top', 'box-shadow']),
  ['@media (hover: hover)']: {
    ['&:hover']: {
      top: '-5px',
      boxShadow: `${theme.palette.shadows.strong} !important`,
    },
  },
  // end card effect
  ...(whiteBg && {
    boxShadow: theme.palette.shadows.lightWhiteBg,
    ['@media (hover: hover)']: {
      ['&:hover']: {
        boxShadow: `${theme.palette.shadows.strongWhiteBg} !important`,
      },
    },
  }),
  ...(disableHover && {
    boxShadow: `${theme.palette.shadows.light} !important`,
    ['@media (hover: hover)']: {
      ['&:hover']: {
        top: 0,
        boxShadow: `${theme.palette.shadows.light} !important`,
      },
    },
  }),
  ...(overflow && {
    overflow: 'visible',
  }),
  ...(fullHeight && {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  }),
}))

const Card = ({ ...props }: CardProps) => {
  return <StyledCard {...props} />
}

export default memo(Card)
