import React, { memo, useState } from 'react'
import {
  GatsbyImage,
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image'
import { Box, Grid, styled, useTheme } from '@mui/material'

import { LocalPostOffice, Add, ArrowBack } from '@mui/icons-material'
import { FaLinkedinIn } from 'react-icons/fa'
import EllipsisText from 'react-ellipsis-text'

import Flex from '_core/components/atoms/layout/Flex'
import Button from '_core/components/atoms/buttons/Button'
import Card from '_core/components/atoms/cards/Card'
import InternalLink from '_core/components/atoms/links/InternalLink'
import ExternalButtonLink from '_core/components/atoms/buttons/ExternalButtonLink'
import ImageHover from '_core/components/atoms/media/ImageHover'

import HeadingDivider from 'public/components/atoms/dividers/HeadingDivider'

interface Props {
  member: Queries.DatoCmsTeamMember
}
interface ContactButtonsProps {
  member: Queries.DatoCmsTeamMember
  colorVariant: 'outline-light' | 'outline-dark'
}

const StyledCard = styled(Card)({
  ['@media not all and (min-resolution:.001dpcm)']: {
    ['@media']: {
      transform: 'translateZ(0) !important',
    },
  },
})

const StyledFlex = styled(Flex)(({ theme }) => ({
  width: '200%',
  height: '100%',
  alignItems: 'stretch',
  transition: theme.transitions.create(['transform'], {
    duration: 500,
  }),
  ['> div']: {
    flex: 1,
  },
  ['&.bio']: {
    transform: 'translateX(-50%)',
  },
}))

const FlipButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  svg: {
    fontSize: '2rem',
  },
}))

const StyledImage = styled(GatsbyImage)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    width: '100%',
    display: 'block',
    height: '0 !important',
    paddingTop: '75%',
  },
}))

const ContactButtons = ({
  colorVariant,
  member,
}: Queries.ContactButtonsProps) => {
  return (
    <>
      <Box mr="5px">
        <ExternalButtonLink
          href={`mailto:${member.emailAddress}`}
          buttonProps={{
            variant: 'outlined',
            colorVariant: colorVariant,
            sizeVariant: 'md',
            round: true,
            ['aria-label']: `Contact ${member.name} via email`,
          }}
        >
          <LocalPostOffice className="icon" fontSize="inherit" />
        </ExternalButtonLink>
      </Box>

      <ExternalButtonLink
        href={member.linkedinProfileUrl}
        buttonProps={{
          variant: 'outlined',
          colorVariant: colorVariant,
          sizeVariant: 'md',
          round: true,
          ['aria-label']: `Contact ${member.name} via LinkedIn`,
        }}
      >
        <FaLinkedinIn />
      </ExternalButtonLink>
    </>
  )
}

const TeamMemberCard = ({ member }: Props) => {
  const theme = useTheme()
  const [on, setOn] = useState(false)

  const p = { xs: 3, sm: 2, md: 3 }
  const pSmall = { xs: 1, sm: 1, md: 2 }

  const images = withArtDirection(
    // @ts-ignore
    getImage(member.imageLandscape),
    [
      {
        media: `(max-width: ${theme.breakpoints.values.xl}px)`,
        image: getImage(member.imagePortrait),
      },
    ]
  )

  return (
    <Grid item xs={12} md={6} xl={4}>
      <StyledCard fullHeight>
        <StyledFlex className={on ? 'bio' : ''}>
          <Box position="relative">
            <InternalLink slug={member.slug} apiKey={member.model.apiKey}>
              <ImageHover style={{ height: 'auto' }}>
                <StyledImage image={images} alt={`Picture of ${member.name}`} />
              </ImageHover>
            </InternalLink>

            <Box px={p} pt={p}>
              <InternalLink slug={member.slug} apiKey={member.model.apiKey}>
                <Box component="h3" sx={{ typography: 'h5', mb: 0 }}>
                  {member.name}
                </Box>
              </InternalLink>
              {member.jobTitle}
              <HeadingDivider sx={{ mt: 2, mb: 3, width: '3.5rem' }} />
            </Box>

            <Flex mt="auto" px={p} pb={pSmall}>
              <ContactButtons colorVariant="outline-dark" member={member} />
            </Flex>

            <FlipButton
              variant="contained"
              color="primary"
              sizeVariant="xxl"
              round
              sharp
              onClick={() => setOn(true)}
              aria-label="Find out more"
            >
              <Add />
            </FlipButton>
          </Box>

          <Box position="relative">
            <Flex
              flexDirection="column"
              fullHeight
              bgcolor="textDark"
              color="rgba(255,255,255,.7)"
            >
              <Box px={p} pt={p} pb={pSmall}>
                <Box
                  component="h3"
                  sx={{ typography: 'h5', mb: 0, color: 'common.white' }}
                >
                  {member.name}
                </Box>
                {member.jobTitle}
                <HeadingDivider sx={{ mt: 2, mb: 3, width: '3.5rem' }} />
                <EllipsisText text={member.bio} length={150} />

                <Box mt={p} color="white">
                  <InternalLink slug={member.slug} apiKey={member.model.apiKey}>
                    <Box typography="smallText" className="border">
                      View Profile
                    </Box>
                  </InternalLink>
                </Box>
              </Box>
              <Flex mt="auto" px={p} pb={pSmall}>
                <ContactButtons colorVariant="outline-light" member={member} />
              </Flex>

              <FlipButton
                variant="contained"
                color="primary"
                sizeVariant="xl"
                round
                sharp
                onClick={() => setOn(false)}
                aria-label="Return"
              >
                <ArrowBack />
              </FlipButton>
            </Flex>
          </Box>
        </StyledFlex>
      </StyledCard>
    </Grid>
  )
}

export default memo(TeamMemberCard)
