import React, { memo } from 'react'
import { Box, Container, ContainerProps, Typography } from '@mui/material'

import Section, { SectionProps } from '_core/components/atoms/layout/Section'

interface Props {
  smallHeading?: string
  leadText: string
  text?: string
  sectionProps?: SectionProps
  containerProps?: ContainerProps
}

const TextSection = ({
  smallHeading,
  leadText,
  text,
  containerProps,
}: Props) => {
  return (
    <Section vSpace>
      <Container maxWidth="lg" vertical-padding="true" {...containerProps}>
        {smallHeading && (
          <Typography
            component="div"
            variant="smallText"
            color="primary.main"
            sx={{ mt: 2 }}
          >
            {smallHeading}
          </Typography>
        )}
        <Box component="h2" sx={{ typography: 'h4', mt: 2, mb: text ? 4 : 2 }}>
          {leadText}
        </Box>
        {text && (
          <Box
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}
      </Container>
    </Section>
  )
}

export default memo(TextSection)
