import React, { memo } from 'react'
import { Box, BoxProps, styled } from '@mui/material'

const Hover = styled(Box)(({ theme }) => ({
  display: 'block',
  background: theme.palette.secondary.main,
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  ['.gatsby-image-wrapper']: {
    width: '100%',
    height: '100%',
    opacity: 1,
    transform: 'scale(1.01)',
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: 500,
    }),
    ['&.dynamic']: {
      img: {
        position: 'relative',
      },
    },
  },
  ['@media (hover: hover)']: {
    ['&:hover']: {
      ['.gatsby-image-wrapper']: {
        opacity: '0.25',
        transform: 'scale(1.08)',
      },
    },
  },
}))

const ImageHover = ({ ...props }: BoxProps) => <Hover {...props} />

export default memo(ImageHover)
