import React, { memo } from 'react'

import { Box, BoxProps, Typography, TypographyProps } from '@mui/material'
import HeadingDivider from 'public/components/atoms/dividers/HeadingDivider'

interface Props extends TypographyProps {
  smallHeading?: string
  heading: string | React.ReactNode
  boxProps?: BoxProps
}

const SectionHeading = ({
  smallHeading,
  heading,
  boxProps,
  ...props
}: Props) => {
  return (
    <Box textAlign="center" mb={6} {...boxProps}>
      {smallHeading && (
        <Typography variant="smallText" component="span" sx={{ my: '1.5rem' }}>
          {smallHeading}
        </Typography>
      )}
      <Typography variant="h2" sx={{ textAlign: 'center' }} {...props}>
        {heading}
      </Typography>
      <HeadingDivider sx={{ mt: 2, mx: 'auto' }} />
    </Box>
  )
}

export default memo(SectionHeading)
